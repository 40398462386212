<template>
  <DashboardTemplate v-loading.fullscreen.lock="loading">
    <Layout class="pd-y-3" v-if="!loading">
      <FormData
        :edit="false"
        :routerBack="`/system/user/company/${$route.params.id}`"
        :ruleForm="ruleForm"
        :validate="true"
        @emitData="saveData"
        @getNewData="getDetail"
      />
    </Layout>
  </DashboardTemplate>
</template>
<script>
import Layout from "@/template/LayoutDashboardTemplate";
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import FormData from "@/components/formData/FormCompanyData";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate,
    FormData,
    Layout
  },
  data() {
    return {
      loading: false,
      ruleForm: {
        userId: this.$route.params.id,
        memberOf: [],
        memberOfEtc: null,
        emailEdit: "",
        mobilephoneEdit: "",
        companyId: 0,
        companyName: "",
        corporateNumber: "",
        position: null,
        businessType: null,
        cateIndustryType: null,
        industryType: null,
        industryTypeEtc: "",
        employment: null,
        businessSize: "S",
        yearEstablished: 0,
        latestAnnualRevenues: 0,
        latestAnnualProfits: 0,
        exportExperience: 0,
        exportSales: 0,
        exportRatio: 0,
        orderConsistency: 0,
        exportFrequency: 0,
        exportCountry: 0,
        trainingExpectations: 0,
        expectedPercentage: 0,
        expectedPercentageEtc: 0,
        moreExportExpectations: 0,
        year: "2021",
        status: 1,
        juristicId: "",
        operatorType: null,
        exportPlan: null,
        shipment: 0,
        paymentTerm: 0,
        exportValue: 0,
        isProduction: 2,
        companyNameEdit: ""
      }
    };
  },
  mounted() {
    if (typeof this.$route.params.companyId != "undefined") {
      this.getDetail();
    }
  },
  methods: {
    getmember(member) {
      let data = [];
      member.map((item, index) => {
        if (item != 6) {
          let filter = this.$store.state.memberOfOrganize.filter(
            a => a.value == item
          );

          if (filter.length > 0) {
            data.push({
              userId: this.$route.params.id,
              memberOf: filter[0].value,
              memberOfEtc: filter[0].label
            });
          }
        } else {
          data.push({ memberOf: 6, memberOfEtc: this.ruleForm.memberOfEtc });
        }
      });
      return data;
    },
    saveData(data) {
      let memberof = this.getmember(data.memberOf);
      let obj = {
        userId: this.$route.params.id,
        memberOf: memberof,
        memberOfEtc: data.memberOfEtc == null ? "" : data.memberOfEtc,
        emailEdit: data.emailEdit,
        mobilephoneEdit: data.mobilephoneEdit,
        companyId: null,
        companyName: data.companyName,
        corporateNumber: data.corporateNumber,
        position:
          data.memberOfEtc == null || !data.position ? 0 : data.position,
        businessType:
          data.businessType == null || !data.businessType
            ? 0
            : data.businessType,
        cateIndustryType:
          data.cateIndustryType == null || !data.cateIndustryType
            ? 0
            : data.cateIndustryType,
        industryType:
          data.industryType == null || !data.industryType
            ? 0
            : data.industryType,
        industryTypeEtc: data.industryTypeEtc,
        employment:
          data.employment == null || !data.employment ? 0 : data.employment,
        businessSize: data.businessSize,
        yearEstablished: data.yearEstablished ? data.yearEstablished : 0,
        latestAnnualRevenues: data.latestAnnualRevenues,
        latestAnnualProfits: data.latestAnnualProfits,
        exportExperience:
          data.exportCountry == null || !data.exportExperience
            ? 0
            : data.exportExperience,
        exportSales:
          data.exportSales == null || !data.exportSales ? 0 : data.exportSales,
        exportRatio:
          data.exportRatio == null || !data.exportRatio ? 0 : data.exportRatio,
        orderConsistency:
          data.orderConsistency == null || !data.orderConsistency
            ? 0
            : data.orderConsistency,
        exportFrequency:
          data.exportFrequency == null || !data.exportFrequency
            ? 0
            : data.exportFrequency,
        exportCountry:
          data.exportCountry == null || !data.exportCountry
            ? 0
            : data.exportCountry,
        trainingExpectations:
          data.trainingExpectations == null || !data.trainingExpectations
            ? 0
            : data.trainingExpectations,
        expectedPercentage:
          data.expectedPercentage == null || !data.expectedPercentage
            ? 0
            : data.expectedPercentage,
        expectedPercentageEtc:
          data.expectedPercentageEtc == null || !data.expectedPercentageEtc
            ? 0
            : data.expectedPercentageEtc,
        moreExportExpectations:
          data.moreExportExpectations == null || !data.moreExportExpectations
            ? 0
            : data.moreExportExpectations,
        year: new Date().getFullYear(),
        status: data.status,
        juristicId: data.juristicId,
        operatorType:
          data.operatorType == null || !data.operatorType
            ? 0
            : data.operatorType,
        exportPlan:
          data.exportPlan == null || !data.exportPlan ? 0 : data.exportPlan,
        shipment: data.shipment == null || !data.shipment ? 0 : data.shipment,
        paymentTerm:
          data.paymentTerm == null || !data.paymentTerm ? 0 : data.paymentTerm,
        exportValue:
          data.exportValue == null || !data.exportValue ? 0 : data.exportValue,
        isProduction:
          data.isProduction == null || !data.isProduction
            ? 0
            : data.isProduction,
        companyNameEdit: data.companyNameEdit
      };
      HTTP.put(`system/admin/update/company`, obj)
        .then(result => {
          console.log("system/admin/update/company", result);
          if (result.data.success) {
            this.$message({
              showClose: true,
              message: "เพิ่มข้อมูลสำเร็จ",
              type: "success"
            });
          }
          this.$router.push(`/system/user/company/${this.$route.params.id}`);
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    getDetail() {
      this.loading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(
        `/information/${this.$route.params.id}/${this.$route.params.companyId}`
      )
        .then(res => {
          if (res.data.success) {
            this.ruleForm = JSON.parse(JSON.stringify(res.data.obj));
            this.ruleForm.memberOf = [];

            res.data.obj.memberOf.map(data => {
              this.ruleForm.memberOf.push(data.memberOf);
              if (data.memberOf == 6) {
                this.ruleForm.memberOfEtc = data.memberOfEtc;
              } else if (data.memberOf == 7) {
                this.ruleForm.memberOfEtc = "EXAC";
              }
            });

            res.data.obj.operatorType == 0 || res.data.obj.operatorType == ""
              ? (this.ruleForm.operatorType = null)
              : null;
            if (res.data.obj.exportRatio == 0) {
              this.ruleForm.exportRatio = "";
            }
            if (res.data.obj.operatorType == 2) {
              this.ruleForm.operatorType = "";
            }
            if (this.ruleForm.companyNameEdit == "") {
              this.ruleForm.companyNameEdit = this.ruleForm.companyName;
            }

            this.ruleForm.oldCompanyNumber = this.ruleForm.corporateNumber;

            if (typeof this.$route.params.id != "undefined") {
              this.ruleForm.role = 0;
            } else {
              this.originalImg = res.data.obj.profileImg;
              this.$store.commit(
                "SET_USER",
                JSON.parse(JSON.stringify(res.data.obj))
              );
            }
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>